import axios from "axios";

export const fetchLatLong = async (address) => {
    try {
        const encodedAddress = encodeURIComponent(address);
        const url = `https://api-adresse.data.gouv.fr/search/?q=${encodedAddress}&type=housenumber&autocomplete=0`;

        const response = await axios.get(url);

        if (response.data && response.data.features && response.data.features.length > 0) {
            const { geometry } = response.data.features[0];

            if (geometry && geometry.coordinates) {
                const [longitude, latitude] = geometry.coordinates;
                return { latitude, longitude };
            }
        }

        throw new Error('No coordinates found for the given address.');
    } catch (error) {
        console.error(error);
        throw new Error('An error occurred while fetching the coordinates.');
    }
};

export const saveToLocalStorage = (key, value) => {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error('Error saving to localStorage', error);
    }
};

export const loadFromLocalStorage = (key) => {
    try {
        const serializedValue = localStorage.getItem(key);
        if (serializedValue === null) return undefined;
        return JSON.parse(serializedValue);
    } catch (error) {
        console.error('Error reading from localStorage', error);
        return undefined;
    }
};

export const removeFromLocalStorage = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error('Error removing item from localStorage', error);
    }
};

export const cleanLocalStorage = () => {
    try {
        removeFromLocalStorage("depositResult");
        removeFromLocalStorage("formData");
        removeFromLocalStorage("prestaId");
        removeFromLocalStorage("answers");
        removeFromLocalStorage("dbObject");
        removeFromLocalStorage("agendaObject");
        removeFromLocalStorage("selectedTime");
        removeFromLocalStorage("selectedDate");
    } catch (error) {
        console.error('Error removing item from localStorage', error);
    }
};
