import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {onAuthStateChanged, signInAnonymously} from "firebase/auth";
import {cancelEvent, cancelRdv, firebaseAuth, getRdv} from "../firebase";
import {
    Backdrop,
    CircularProgress, Dialog,
    DialogActions,
    DialogContent, MenuItem,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import pinMapBlue from "../assets/ph_map-pin_blue.png";
import calPlusBlue from "../assets/ph_calendar-check_blue.png";
import pinMapDark from "../assets/mapPinDark.png";
import calPlusDark from "../assets/calendarXDark.png";
import cancelRound from "../assets/cancelRound.svg";
import {Gallery} from "./carouselpresta";
import Button from "@mui/material/Button";
import { ICalendar } from 'datebook'
import {CalendarOptions} from "datebook";
import { saveAs } from 'file-saver';
import userlogo from "../assets/rec.png";
import SnackbarWithCustomBackground from "./snackbar";
import DisplayCarousel from "./displayCarousel";
import dollaIc from "../assets/dollaIc.svg";
import checkGreenIc from "../assets/ic_check.svg";
import {cleanLocalStorage, removeFromLocalStorage} from "./utils";

function formatDate(date) {
    const dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    const monthNames = Object.keys(monthMap);
    return `${dayNames[date.getDay()]} ${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
}
const monthMap = {
    "janvier": 0,
    "février": 1,
    "mars": 2,
    "avril": 3,
    "mai": 4,
    "juin": 5,
    "juillet": 6,
    "août": 7,
    "septembre": 8,
    "octobre": 9,
    "novembre": 10,
    "décembre": 11
};

const cancelOptions = ["Changement de réservation", "Double réservation", "Imprévu santé", "Retard conséquent", "Urgence personnelle", "Vacances ou absence", "Autre"];

function convertToTimeZone(date) {
    const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = formatter.format(date);
    return new Date(formattedDate);
}

function formatHour(date) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}h${minutes}`;
}

function durationFormatMin(duration){
    const [hours, minutes] = duration.split('h');
    return parseInt(hours) === 0 ? `${minutes} min` : `${duration}`;
}

export default function RdvStatus() {

    const location = useLocation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const {id, id2 } = useParams();

    const [displayPage, setDisplayPage] = useState(null);
    const [displayQ, setDisplayQ] = useState(false);
    const [rdvData, setRdvData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isNoCancelDialogOpen, setIsNoCancelDialogOpen] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [selectedCancel, setSelectedCancel] = useState("");
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        const unregisterAuthObserver = onAuthStateChanged(  firebaseAuth,  async ( user) => {
            if (!(user && user.isAnonymous)) {
                try {
                    await signInAnonymously(firebaseAuth);
                } catch (e) {
                    console.log("Auth error => ", e.message);
                    setDisplayPage(false);
                }
            }

            cleanLocalStorage();

            if (user){
                const result = await getRdv({id:id2});
                if (result.data.success){
                    setRdvData(result.data.rdvData);
                    setUserData(result.data.userData);
                    setDisplayPage(true);
                } else {
                    setDisplayPage(false);
                }
            }
        });

        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const handleAddToCalendar = async () => {

        const config: CalendarOptions = {
            title: rdvData.prestaSnapshot.title,
            description:"Gérer ma réservation : https://faismoibg.com/"+id+"/rdvstatus/" + id2 +  " Description : " + rdvData.prestaSnapshot.description,
            location: "",
            start: new Date(rdvData.start._seconds * 1000),
            end: new Date(rdvData.endDateClient._seconds * 1000),
        }
        const icalendar = new ICalendar(config);
        const blob = new Blob([icalendar.render()], {
            type: 'text/calendar'
        })
        saveAs(blob, 'rdv' + new Date().toISOString() + '.ics')
    }

    const navigateHome = async () => {
        await navigate("/"+userData.urlPerso);
    }

    const navigateFromToAnswers = async () => {

        setDisplayQ(!displayQ);

        /*await navigate("/"+id+"/rdvanswers/"+id2,{
            state : {
                rdvData : rdvData
            }
        });*/
    }

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const openDialog = () => {
        if (rdvData?.prestaSnapshot?.isDeposit && rdvData?.depositRef?.length>0){
            setIsNoCancelDialogOpen(true);
        } else {
            setIsDialogOpen(true);
        }
    };

    const navigateToCancel = async () => {
        if (selectedCancel.length<2){
            handleOpenSnackbar("Vous devez séléctionner un motif.");
            return;
        }
        setIsDialogOpen(false);
        setShowBackdrop(true);

        if (rdvData.isGoogle){
            const today = new Date(rdvData.start._seconds * 1000);
            const tomorrow = new Date(rdvData.end._seconds * 1000);
            today.setDate(today.getDate()-1);
            tomorrow.setDate(tomorrow.getDate() + 1);
            try {
                const canceledGoogleEvent = await cancelEvent({
                    uidFromCall : rdvData.uid,
                    eventId : Array.from(new TextEncoder().encode(String(rdvData.documentId)))
                        .map(byte => (byte < 10 ? '0' : '') + byte.toString(32))
                        .join('')
                        .toLowerCase(),
                    today : today.toISOString(),
                    tomorrow : tomorrow.toISOString()
                })
            } catch (e) {
                console.log(e);
            }
        }

        const result = await cancelRdv({dataToUpdate:{
                isCanceled:true,
                cancelReason : selectedCancel,
                cancelPerson : rdvData.custAddress,
                timestamp : new Date()
            }, rdvId:id2});
        if (result.data.success){
            setShowBackdrop(false);
            window.location.reload();
        } else {
            handleOpenSnackbar("Ooops, quelque chose s'est mal passé, réessayez ou contactez nous");
            setShowBackdrop(false);
        }
    }

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleCancelSelection = (option) => {
        setSelectedCancel(option);
    }

    return (
        <div className="sub-container">

            {displayPage===null ? (
                <div className="sub-container-no-top">
                    <div style={{display:"flex", width:"100%", flexDirection:"column",
                        justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                        <Skeleton style={{marginTop:"10px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    </div>
                </div>
            ) : (
                displayPage ? (
                    displayQ ? (
                        <div className="sub-container-no-top">
                            <span className="title-style" style={{textAlign:"center"}}>Informations complémentaires</span>
                            <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px", whiteSpace:"pre-line"}}>
                            {rdvData.prestaSnapshot.intro}
                        </span>
                            {rdvData.answers.map((object,index)=> (
                                <div style={{display:"flex", flexDirection:"column", width:"100%", marginTop:"50px"}} key={index}>
                                <span style={{color:"#4808FF", fontSize:"16px", lineHeight:"24px", fontWeight:400}}>
                                    Question {index + 1}
                                </span>
                                    <span style={{color:"#17181A", fontSize:"16px", lineHeight:"24px", fontWeight:700, marginTop:"20px"}}>
                                    {object?.question}
                                </span>
                                    <span style={{color:"#17181A", fontSize:"16px", lineHeight:"24px", fontWeight:400, marginTop:"20px"}}>
                                    {object?.answer}
                                </span>
                                    {
                                        object.checked && (
                                            <div style={{marginTop:"15px", display:"flex", width:"100%"}}>
                                                {object?.images?.length<1 ?
                                                    <div>Aucune image</div> : <DisplayCarousel style={{marginTop:"10px"}} imagesArray={object?.images}
                                                    />
                                                }
                                            </div>
                                        )
                                    }
                                </div>

                            ))}

                            <div className="button-container" style={{marginTop:"70px", marginBottom:"100px"}}>
                                <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateFromToAnswers}>
                                    <Typography variant="button" style={{ textTransform: 'none' }}>
                                        Retour
                                    </Typography>
                                </Button>
                            </div>

                        </div>
                        ) : (
                    <div className="sub-container-no-top">
                        <span className="title-style" style={{textAlign:"center"}}>Votre réservation est {rdvData.isCanceled ?
                            <span className="title-style" style={{textAlign:"center", color:"#FF0844"}}>annulée</span> : (rdvData?.prestaSnapshot?.isValidation && !rdvData?.isValidated ?
                                    (<span className="title-style" style={{textAlign:"center", color:"#FBBC04"}}>en attente de confirmation</span>) :
                                    (<span className="title-style" style={{textAlign:"center", color:"#28A745"}}>validée</span>))
                        }
                        </span>
                        <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px"}}>
                                                   Depuis le {formatDate(convertToTimeZone(new Date(rdvData.timestamp._seconds * 1000)))}
                        </span>
                        {rdvData?.prestaSnapshot?.isValidation && !rdvData?.isValidated && !rdvData.isCanceled ? (
                                <div className="sub-container-no-top">

                                    <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px"}}>
                                         Votre beauty artiste a reçu votre demande. Vous recevrez un email après confirmation.
                                    </span>
                                </div>

                            ) : (
                            <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px"}}>
                                Nous vous avons envoyé un email de confirmation.
                            </span>
                            )}

                        {rdvData?.prestaSnapshot?.isDeposit && rdvData?.depositRef?.length>0 && (
                            <div style={{width:"100%",marginBottom : rdvData?.isCanceled ? "18px" : "0px"}}>
                                <div style={{display: "flex", padding: "20px 20px 20px 20px", flexDirection: "column", justifyContent: "center", alignItems: "flex-start",
                                    gap: "30px", borderRadius: "15px",
                                    background: "linear-gradient(180deg, #DAE0E5 0%, #FFF 100%)",
                                    boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.90)", marginTop:"60px"}}>

                                    <div style={{flexDirection:"column", display:"flex", width:"100%"}}>

                                        <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                                <span style={{fontWeight: 700, lineHeight: "20px"}}>
                                                    Acompte confirmé
                                                </span>
                                            <img style={{marginLeft:"20px",marginRight:"7px"}} src={checkGreenIc}/>
                                        </div>

                                        <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between", marginTop:"10px"}}>
                                                <span style={{ lineHeight: "20px"}}>
                                                    Montant
                                                </span>
                                            <div style={{display: "flex", padding:" 5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", fontWeight:700, fontSize:"14px"}}>
                                                {rdvData?.prestaSnapshot?.deposit?.amount}
                                            </div>
                                        </div>
                                        <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"start", marginTop:"10px"}}>
                                                <span style={{ lineHeight: "20px"}}>
                                                    Ref :
                                                </span>
                                            <div style={{marginLeft:"6px"}}>
                                                {rdvData?.depositRef}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}
                        {rdvData?.prestaSnapshot?.isValidation && !rdvData?.isValidated && !rdvData.isCanceled &&(
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "32px",
                                    minHeight: "10px",
                                    borderRadius: "15px",
                                    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                    width: "100%",
                                    flexDirection: "column", // Change to column to stack items vertically
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "20px 20px",
                                    }}
                                >
                                    <div style={{display:"flex", flexDirection:"column", width:"80%"}}>
                                        <span style={{fontWeight:700}}>
                                            En attente de confirmation par le beauty artiste
                                        </span>
                                    </div>
                                    <div style={{display:"flex", flexDirection:"column", width:"20%",alignContent:"center", alignItems:"center", justifyContent:"center"}}>
                                        <img style={{alignSelf:"end"}} src={cancelRound}/>
                                    </div>
                                </div>

                            </div>
                        )}
                        {rdvData.isCanceled && (
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "32px",
                                    minHeight: "10px",
                                    borderRadius: "15px",
                                    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                    width: "100%",
                                    flexDirection: "column", // Change to column to stack items vertically
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "20px 20px",
                                    }}
                                >
                                    <div style={{display:"flex", flexDirection:"column", width:"70%"}}>
                            <span style={{fontWeight:700}}>
                                Motif d’annulation
                            </span>
                                        <span style={{}}>
                                {rdvData?.cancelReason}
                            </span>
                                        <br/>
                                        <span style={{fontWeight:700}}>
                                Effectuée par
                            </span>
                                        <span style={{}}>
                                {rdvData?.cancelPerson}
                            </span>

                                    </div>
                                    <div style={{display:"flex", flexDirection:"column", width:"30%",alignContent:"center", alignItems:"center", justifyContent:"center"}}>
                                        <img style={{alignSelf:"center"}} src={cancelRound}/>
                                    </div>
                                </div>

                            </div>
                        )}
                        {rdvData.isCanceled ?
                            <div
                                style={{
                                    display: "flex",
                                    background: "linear-gradient(180deg, #8A9699 0%, #000 100%)",
                                    marginTop: "50px",
                                    minHeight: "10px",
                                    borderRadius: "15px",
                                    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                    width: "100%",
                                    flexDirection: "column", // Change to column to stack items vertically
                                }}
                            >
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row", // Change to row to have icon and content side by side
                                    alignItems: "center", // Center icon and content vertically
                                    padding: "1px 20px", // Add padding to create some space around the content
                                    marginBottom:"25px"
                                }}
                            >
                                <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                    <img src={calPlusDark} style={{width:"30px", height:"30px"}}/>
                                </div>
                                <div
                                    style={{
                                        width: "90%", // Adjusted width to make space for the close icon
                                        marginLeft: "10px",
                                    }}
                                >
                                    <span style={{color:"white"}}>{formatDate(convertToTimeZone(new Date(rdvData.start._seconds * 1000)))} à {formatHour(convertToTimeZone(new Date(rdvData.start._seconds * 1000)))}</span>
                                </div>
                            </div>
                            {(rdvData?.prestaSnapshot?.selectedAddress?.length>1) ?
                                    (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row", // Change to row to have icon and content side by side
                                                alignItems: "center", // Center icon and content vertically
                                                padding: "1px 20px", // Add padding to create some space around the content
                                                marginBottom:"25px"
                                            }}
                                        >
                                            <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                                <img src={pinMapDark} style={{width:"30px", height:"30px"}}/>
                                            </div>
                                            <div
                                                style={{
                                                    width: "90%", // Adjusted width to make space for the close icon
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                <span style={{color:"white"}}>{rdvData.prestaSnapshot.selectedAddress}</span>
                                            </div>
                                        </div>
                                    ) :
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row", // Change to row to have icon and content side by side
                                            alignItems: "center", // Center icon and content vertically
                                            padding: "1px 20px", // Add padding to create some space around the content
                                            marginBottom:"25px"
                                        }}
                                    >
                                        <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                            <img src={pinMapDark} style={{width:"30px", height:"30px"}}/>
                                        </div>
                                        <div
                                            style={{
                                                width: "90%", // Adjusted width to make space for the close icon
                                                marginLeft: "10px",
                                            }}
                                        >
                                            <span style={{color:"white"}}>Aucune adresse pour cette prestation, contactez votre beauty artist.</span>
                                        </div>
                                    </div>

                            }
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "1px 20px", // Add padding to create some space around the content
                                        marginBottom:"25px"
                                    }}
                                >
                                    <div style={{ width: "10%", alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                        <img onLoad={handleImageLoad}
                                             className="fade-in" style={{color:"white", borderRadius:"50%", width:"30px",height:"30px", objectFit:"cover"}}
                                             src={userData.imageUrl.length> 0 ? userData.imageUrl : userlogo }/>
                                    </div>
                                    <div
                                        style={{
                                            width: "90%", // Adjusted width to make space for the close icon
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <span style={{color:"white"}}>{userData.pseudo}</span>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "1px 20px", // Add padding to create some space around the content
                                        marginBottom:"25px"
                                    }}
                                >
                                    <div style={{ width: "10%", alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                        <img onLoad={handleImageLoad}
                                             className="fade-in" style={{color:"white", borderRadius:"5px", width:"30px",height:"30px", objectFit:"cover"}}
                                             src={rdvData.prestaSnapshot.imageLinks.length> 0 ? rdvData.prestaSnapshot.imageLinks[0].imageLink : "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.." }/>
                                    </div>
                                    <div
                                        style={{
                                            width: "90%", // Adjusted width to make space for the close icon
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <span style={{color:"white", fontWeight:700}}>{rdvData.prestaSnapshot.title}</span>
                                    </div>
                                </div>


                </div> : <div
                                style={{
                                    display: "flex",
                                    background: "linear-gradient(180deg, #4808FF 0%, #9747FF 100%)",
                                    marginTop: "50px",
                                    minHeight: "10px",
                                    borderRadius: "15px",
                                    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                    width: "100%",
                                    flexDirection: "column", // Change to column to stack items vertically
                                }}
                            >
                                <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "1px 20px", // Add padding to create some space around the content
                                        marginBottom:"25px"
                                    }}
                                >
                                    <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                        <img src={calPlusBlue} style={{width:"30px", height:"30px"}}/>
                                    </div>
                                    <div
                                        style={{
                                            width: "90%", // Adjusted width to make space for the close icon
                                            marginLeft: "10px",
                                            fontSize:"16px"
                                        }}
                                    >
                                        <span style={{color:"white"}}>{formatDate(convertToTimeZone(new Date(rdvData.start._seconds * 1000)))} à {formatHour(convertToTimeZone(new Date(rdvData.start._seconds * 1000)))}</span>
                                    </div>
                                </div>
                                {(rdvData?.prestaSnapshot?.selectedAddress?.length>1) ?
                                    (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row", // Change to row to have icon and content side by side
                                                alignItems: "center", // Center icon and content vertically
                                                padding: "1px 20px", // Add padding to create some space around the content
                                                marginBottom:"25px"
                                            }}
                                        >
                                            <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                                <img src={pinMapBlue} style={{width:"30px", height:"30px"}}/>
                                            </div>
                                            <div
                                                style={{
                                                    width: "90%", // Adjusted width to make space for the close icon
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                <span style={{color:"white"}}>{rdvData?.prestaSnapshot?.selectedAddress}</span>
                                            </div>
                                        </div>
                                    ) :
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row", // Change to row to have icon and content side by side
                                            alignItems: "center", // Center icon and content vertically
                                            padding: "1px 20px", // Add padding to create some space around the content
                                            marginBottom:"25px"
                                        }}
                                    >
                                        <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                            <img src={pinMapBlue} style={{width:"30px", height:"30px"}}/>
                                        </div>
                                        <div
                                            style={{
                                                width: "90%", // Adjusted width to make space for the close icon
                                                marginLeft: "10px",
                                            }}
                                        >
                                            <span style={{color:"white"}}>Aucune adresse pour cette prestation, contactez votre beauty artist.</span>
                                        </div>
                                    </div>

                                }

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "1px 20px", // Add padding to create some space around the content
                                        marginBottom:"25px"
                                    }}
                                >
                                    <div style={{ width: "10%", alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                        <img onLoad={handleImageLoad}
                                             className="fade-in" style={{color:"white", borderRadius:"50%", width:"30px",height:"30px", objectFit:"cover"}}
                                             src={userData?.imageUrl?.length> 0 ? userData?.imageUrl : userlogo }/>
                                    </div>
                                    <div
                                        style={{
                                            width: "90%", // Adjusted width to make space for the close icon
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <span style={{color:"white"}}>{userData.pseudo}</span>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "1px 20px", // Add padding to create some space around the content
                                        marginBottom:"25px"
                                    }}
                                >
                                    <div style={{ width: "10%", alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                        <img onLoad={handleImageLoad}
                                             className="fade-in" style={{color:"white", borderRadius:"5px", width:"30px",height:"30px", objectFit:"cover"}}
                                             src={rdvData?.prestaSnapshot?.imageLinks?.length> 0 ? rdvData?.prestaSnapshot?.imageLinks[0]?.imageLink : "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.." }/>
                                    </div>
                                    <div
                                        style={{
                                            width: "90%", // Adjusted width to make space for the close icon
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <span style={{color:"white", fontWeight:700}}>{rdvData.prestaSnapshot.title}</span>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            rdvData?.answers?.length>0 && (
                                <div style={{width:"100%", display:"flex", justifyContent:"center", marginTop:"32px"}}>
                                    <Button onClick={navigateFromToAnswers} disableElevation component="label" className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                                        <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px", lineHeight:"16px" }}>
                                            Lire les réponses au questionnaire
                                        </Typography>
                                    </Button>
                                </div>
                            )
                        }

                        <div style={{display:"flex", marginTop:"32px", width:"100%"}}>
                            <span className="title-style-presta">{rdvData.prestaSnapshot.title}</span>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", marginTop:"10px", width:"100%"}}>
                            <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5"}}>
                                <span style={{color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                    {rdvData.prestaSnapshot.price}
                                </span>
                            </div>
                            <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", marginLeft:"10px"}}>
                                <span style={{color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                    {durationFormatMin(rdvData.prestaSnapshot.prestaDuration)}
                                </span>
                             </div>
                        </div>

                        <div style={{display:"flex", width:"100%", marginTop:"20px"}}>
                            {rdvData.prestaSnapshot.imageLinks.length>0 ? <Gallery urls={rdvData.prestaSnapshot.imageLinks}>
                            </Gallery> : <Gallery urls={[{
                                imageLink:"https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA..",
                                vlink:""
                            }]}>
                            </Gallery>}
                        </div>

                        <span style={{fontSize:"16px", marginTop:"20px", display:"flex", width:"100%", whiteSpace:"pre-line"}}>
                            {rdvData.prestaSnapshot.description}
                        </span>

                        {
                            rdvData.isCanceled || (rdvData?.prestaSnapshot?.isValidation && !rdvData?.isValidated) ?
                                <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                    <div className="button-container" style={{marginTop:"40px"}}>
                                        <Button startIcon={<img onLoad={handleImageLoad}
                                                                className="fade-in" style={{color:"white", borderRadius:"50%", width:"30px",height:"30px", objectFit:"cover", marginRight:"5px", border:"1px solid black"}}
                                                                src={userData.imageUrl.length> 0 ? userData.imageUrl : userlogo }/>} style={{marginBottom:"80px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateHome}>
                                            <Typography variant="button" style={{ textTransform: 'none' }}>
                                                {userData.pseudo}
                                            </Typography>
                                        </Button>
                                    </div>
                                    {rdvData?.prestaSnapshot?.isValidation && !rdvData?.isValidated && !rdvData?.isCanceled  && (
                                        <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                            <div  className="button-container">
                                                <Button style={{marginBottom:"80px"}} className="button-style-nogr button-style-noborders" disableElevation variant="contained" onClick={openDialog}>
                                                    <Typography variant="button" style={{ textTransform: 'none', textDecoration: "underline" }}>
                                                        Annuler la réservation
                                                    </Typography>
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                :
                                <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                    <div className="button-container">
                                        <Button onClick={handleAddToCalendar} className="button-style button-style-noborders" variant="contained">
                                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                                Ajouter au calendrier
                                            </Typography>
                                        </Button>
                                    </div>
                                    <div className="button-container" style={{marginTop:"15px"}}>
                                        <Button startIcon={<img onLoad={handleImageLoad}
                                                                className="fade-in" style={{color:"white", borderRadius:"50%", width:"30px",height:"30px", objectFit:"cover", marginRight:"5px", border:"1px solid black"}}
                                                                src={userData.imageUrl.length> 0 ? userData.imageUrl : userlogo }/>} style={{marginBottom:"80px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateHome}>
                                            <Typography variant="button" style={{ textTransform: 'none' }}>
                                                {userData.pseudo}
                                            </Typography>
                                        </Button>
                                    </div>
                                    <div  className="button-container">
                                        <Button style={{marginBottom:"80px"}} className="button-style-nogr button-style-noborders" disableElevation variant="contained" onClick={openDialog}>
                                            <Typography variant="button" style={{ textTransform: 'none', textDecoration: "underline" }}>
                                                Annuler la réservation
                                            </Typography>
                                        </Button>
                                    </div>
                                </div>
                        }



                    </div>)
                ):(
                    <div className="sub-container-no-top">
                        <div style={{display: "flex", width:"80%", padding: "20px", flexDirection: "column", justifyContent: "center", marginTop:"30px",
                            alignItems: "center", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                        <span style={{lineHeight: "24px"}}>
                                            Cette page n'existe pas  🤷
                                        </span>
                        </div>
                    </div>
                )
            )}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />

            <Dialog open={isNoCancelDialogOpen} onClose={()=> setIsNoCancelDialogOpen(false)}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto'
                        },
                    }}>
                <DialogContent style={{backgroundColor:"white"}}>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
                        <span style={{fontSize:"16px", lineHeight: "20px", marginTop:"30px"}}>Cette prestation a fait l’objet d’un acompte, si vous souhaitez l’annuler, nous vous invitons à faire part de votre demande en prenant contact directement avec le beauty artiste à travers ses réseaux sociaux ou autres moyens de communication.</span>
                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor:"white"}}>
                    <div className="button-container" style={{marginBottom:"30px",marginTop:"30px", marginLeft:"30px", marginRight:"30px"}}>
                        <Button disableElevation={true} onClick={()=> setIsNoCancelDialogOpen(false)} className="button-style-borders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'black', fontWeight:400, fontSize:"18px" }}>
                                Okay. J’ai compris
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog open={isDialogOpen} onClose={handleDialogClose}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto'
                        },
                    }}>
                <DialogContent style={{backgroundColor:"white"}}>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <span style={{fontSize:"16px", fontWeight:700, marginTop:"30px"}}>Votre motif d'annulation</span>
                        <div style={{display:"flex", width:"100%", flexDirection:"column", paddingTop:"30px", paddingBottom:"20px"}}>
                            {cancelOptions.map((option, index) => (
                                <MenuItem onClick={()=> (handleCancelSelection(option))}
                                          style={{
                                              zIndex:1000,
                                              background:selectedCancel===option
                                                  ? "linear-gradient(150deg, #4808FF 0%, #9747FF 100%)"
                                                  : "#DAE0E5",
                                              marginBottom: "10px",
                                              padding: "10px 15px",
                                              borderRadius:"15px"
                                          }}
                                          key={index}
                                >
                                                            <span style={{width: "100%",
                                                                overflow: "hidden",
                                                                color:selectedCancel===option
                                                                    ? "white"
                                                                    : "black",
                                                                fontWeight:selectedCancel===option
                                                                    ? 700
                                                                    : 400,
                                                                fontSize:"16px",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis"}}>
                                                                {option}
                                                            </span>
                                </MenuItem>
                            ))}

                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor:"white"}}>
                    <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                        <Button onClick={navigateToCancel} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )

}