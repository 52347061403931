import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    Backdrop,
    CircularProgress,
    Dialog, DialogActions, DialogContent,
    Skeleton,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import SnackbarWithCustomBackground from "./snackbar";
import Button from "@mui/material/Button";
import {serverTimestamp} from "@firebase/firestore";
import userlogo from "../assets/rec.png";
import {v4} from "uuid"
import pinMapBlue from "../assets/ph_map-pin_blue.png";
import calPlusBlue from "../assets/ph_calendar-check_blue.png";
import {
    createCalendarEvent,
    depTempToRdv,
    firebaseAuth,
    getFreeTimeSlotsv2cl,
    remTempRes, tempDepRecov,
    updateDepositRdv,
    updateRdv
} from "../firebase";
import {onAuthStateChanged, signInAnonymously} from "firebase/auth";
import {DateTime} from "luxon";
import ReactGA from "react-ga4";
import {FaGlobe, FaStripe} from "react-icons/fa";
import {FaPaypal} from "react-icons/fa6";
import IconButton from "@mui/material/IconButton";

import icLydia from "../assets/iconLydiaWhite.svg";
import icPaylib from "../assets/iconPaylibWhite.svg";
import icWise from "../assets/iconWiseWhite.svg";
import icRevolut from "../assets/iconRevolutWhite.svg";
import icSumUp from "../assets/iconSumUpWhite.svg";
import {CountdownTimer} from "./timerDeposit";
import {cleanLocalStorage, loadFromLocalStorage, saveToLocalStorage} from "./utils";

const validateEmail = (email) => {
    // Basic email validation using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};

function buildRecoverState(recoveryResult){
    const selectedTime = recoveryResult.rdvTempDepositDoc.timeStr;
    const selectedDate = recoveryResult.rdvTempDepositDoc.dateStr.split(" ")[0]+" "+recoveryResult.rdvTempDepositDoc.dateStr.split(" ")[1]
        +" "+recoveryResult.rdvTempDepositDoc.dateStr.split(" ")[2]+" "+recoveryResult.rdvTempDepositDoc.dateStr.split(" ")[3];
    const depositResult = {
        date : new Date(recoveryResult.rdvTempDepositDoc.timestamp._seconds*1000).toISOString(),
        docId : recoveryResult.rdvTempDepositDoc.documentId,
        endDateClient : new Date(recoveryResult.rdvTempDepositDoc.endDateClient._seconds*1000).toISOString(),
        start : new Date(recoveryResult.rdvTempDepositDoc.start._seconds*1000).toISOString(),
        success : true
    }
    const agendaObject = recoveryResult.agendaDoc;
    const userObject = recoveryResult.userDoc;
    let dbObj = recoveryResult.rdvTempDepositDoc;
    dbObj.end = new Date(dbObj.end._seconds * 1000).toISOString();
    dbObj.endDateClient =  new Date(dbObj.endDateClient._seconds * 1000).toISOString();
    dbObj.start =  new Date(dbObj.start._seconds * 1000).toISOString();
    dbObj.timestamp =  new Date(dbObj.timestamp._seconds * 1000).toISOString();
    const prestaId = recoveryResult.rdvTempDepositDoc.prestaSnapshot.documentId;
    const formData = {
        email : recoveryResult.rdvTempDepositDoc.custAddress,
        nom : recoveryResult.rdvTempDepositDoc.custName,
        phone : recoveryResult.rdvTempDepositDoc.custPhone
    }
    const prestaObject = recoveryResult.rdvTempDepositDoc.prestaSnapshot;

    //stays in the cache but not used until page refreshed, will be pulled from cache at this time
    saveToLocalStorage("prestaId", prestaId);
    //needs to be appended to vars for the page display
    saveToLocalStorage("depositResult", depositResult);
    saveToLocalStorage("formData", formData);
    saveToLocalStorage("dbObject", dbObj);
    saveToLocalStorage("agendaObject", agendaObject);
    saveToLocalStorage("userObject", userObject);
    saveToLocalStorage("selectedTime", selectedTime);
    saveToLocalStorage("selectedDate", selectedDate);

    return {prestaObject:prestaObject, formData:formData, dbObj:dbObj, userObject:userObject, agendaObject:agendaObject,
        depositResult:depositResult, selectedDate:selectedDate, selectedTime:selectedTime};
}
function isValidFrenchPhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the input
    const cleanNumber = phoneNumber.replace(/\D/g, '');

    // Check if the number starts with "33" or "0" followed by nine digits
    const isValidFormat = /^(33|0\d{9})$/.test(cleanNumber);

    if (isValidFormat) {
        // If the number starts with "33", replace it with "0"
        const normalizedNumber = cleanNumber.startsWith('33') ? `0${cleanNumber.slice(2)}` : cleanNumber;
        return { isValid: true, phoneNumber: normalizedNumber };
    } else {
        return { isValid: false, phoneNumber: null };
    }
}

function addDurationToTime(duration, baseDate) {
    const regex = /^(\d+)h(\d+)m?$/;
    const match = duration.match(regex);

    if (!match) {
        throw new Error("Invalid duration format");
    }

    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10) || 0;

    const newDate = new Date(baseDate);
    newDate.setHours(newDate.getHours() + hours);
    newDate.setMinutes(newDate.getMinutes() + minutes);

    return newDate;
}

function parseFrenchDateWithTimezone(dateString, timezone) {
    // Parse the user-provided date string in the assumed Paris timezone
    const frenchToEnglishMonth = {
        janvier: 0,
        février: 1,
        mars: 2,
        avril: 3,
        mai: 4,
        juin: 5,
        juillet: 6,
        août: 7,
        septembre: 8,
        octobre: 9,
        novembre: 10,
        décembre: 11,
    };

    const parts = dateString.split(' ');

    const day = parseInt(parts[1]);
    const month = frenchToEnglishMonth[parts[2]];
    const year = parseInt(parts[3]);
    const timeParts = parts[4].split('h');
    const hours = timeParts[0];
    const minutes = timeParts[1];
    const dateStringWithTimezone = `${year}-${(month+1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
    const userDate = DateTime.fromISO(dateStringWithTimezone, { zone: timezone });
    const utcDate = userDate.toUTC();
    return [utcDate.toJSDate(), [day, month, year]];
}

export default function RdvInfo() {

    const { id, id2 } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const location = useLocation();
    const stateData = location?.state;

    const [prestaObject,setPrestaObject] = useState(stateData?.prestaObject || loadFromLocalStorage("dbObject")?.prestaSnapshot);
    const [agendaObject, setAgendaObject] = useState(stateData?.agendaObject ||  loadFromLocalStorage("agendaObject"));
    const [selectedTime, setSelectedTime] = useState(stateData?.selectedItem || loadFromLocalStorage("selectedTime"));
    const [selectedDate, setSelectedDate] = useState(stateData?.date ||  loadFromLocalStorage("selectedDate"));
    const [userObject, setUserObject] = useState(stateData?.userObject ? stateData?.userObject : loadFromLocalStorage("userObject"));
    const [depositResult, setDepositResult] = useState(loadFromLocalStorage("depositResult") ? loadFromLocalStorage("depositResult") : null);
    const [formData, setFormData] = useState(loadFromLocalStorage("formData") ? loadFromLocalStorage("formData") : {
        email : "", nom : "", phone : "", address : []
    });

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));


    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [isDepositDialogOpen, setIsDepositDialogOpen] = useState(false);

    const [displayPage, setDisplayPage] = useState(null);
    const [depRef, setDepRef] = useState("");
    const [lockFields, setLockFields] = useState(false);
    const [dbObj, setDbObj] = useState(loadFromLocalStorage("dbObject"));


    const [isAlreadyValidated, setIsAlreadyValidated] = useState(false);

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        const unregisterAuthObserver = onAuthStateChanged(  firebaseAuth,  async ( user) => {
            if (!(user && user.isAnonymous)) {
                try {
                    await signInAnonymously(firebaseAuth);
                } catch (e) {
                    console.log("Auth error => ", e.message);
                    setDisplayPage(false);
                }
            }
            //warmup
            updateDepositRdv({isWarmUp:true}).catch((e)=>console.log(e));
            updateRdv({isWarmUp:true}).catch((e)=>console.log(e));

            if (user){
                //todo this must move to rdvquestions once you swap pages
                if (loadFromLocalStorage("answers")){
                    //user hit previous with an active résa
                    navigate("/"+id);
                }

                const idRec = new URLSearchParams(location.search).get('idRec');

                if (idRec) {
                    async function fetchData() {
                        try {
                            const recoveryResult = await tempDepRecov({id:idRec});
                            if (recoveryResult?.data?.isBooked){
                                handleOpenSnackbar("Vous avez déjà validé ce rendez-vous");
                                setIsAlreadyValidated(true);
                                setDisplayPage(false);
                            } else if (recoveryResult?.data?.success && recoveryResult.data.userDoc.urlPerso===id
                                && recoveryResult.data.rdvTempDepositDoc.prestaSnapshot.documentId===id2){
                                const valuesForDisplay = buildRecoverState(recoveryResult.data);
                                setPrestaObject(valuesForDisplay.prestaObject);
                                setDepositResult(valuesForDisplay.depositResult);
                                setSelectedDate(valuesForDisplay.selectedDate);
                                setSelectedTime(valuesForDisplay.selectedTime);
                                setAgendaObject(valuesForDisplay.agendaObject);
                                setUserObject(valuesForDisplay.userObject);
                                setDbObj(valuesForDisplay.dbObj);
                                setFormData({
                                    ...formData,
                                    phone:valuesForDisplay.formData.phone,
                                    email:valuesForDisplay.formData.email,
                                    nom:valuesForDisplay.formData.nom
                                })
                                setLockFields(true);
                                setDisplayPage(true);
                            } else {
                                setDisplayPage(false);
                            }
                        } catch (error) {
                            console.error(error);
                            handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.")
                            setDisplayPage(false);
                        }
                    }
                    await fetchData();
                } else if (prestaObject!=null && agendaObject!=null && selectedTime!=null && selectedDate!=null && userObject!=null){
                    const localId = loadFromLocalStorage("prestaId");
                    if (depositResult!=null && formData!=null && localId===id2 && dbObj!=null){
                        setLockFields(true);
                    }
                    setDisplayPage(true);
                }  else {
                    setDisplayPage(false);
                }
            }
        });

        return () => {
            unregisterAuthObserver();
        }
    }, []);

    useEffect( () => {
        if (displayPage && depositResult) {
            (async () => {
                await handleConfirm();
            })();
        }
    }, [displayPage, depositResult]);

    const handleFormChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleDepositDialogCloseOutside = () => {
        setIsDepositDialogOpen(false);
    };

    const handleDepositValidated = async () => {
        const now = new Date().getTime();
        const endTime = new Date(depositResult.date).getTime() + 30 * 60 * 1000;
        const distance = endTime - now;
        if (distance <= 0) {
            handleOpenSnackbar("Le délai pour réserver ce créneau a expiré, veuillez recommencer le processus de réservation si vous n'avez pas eu le temps de terminer.");
            setShowBackdrop(false);
            return;
        }
        if (depRef.length<2){
            handleOpenSnackbar("Vous devez ajouter la référence de votre acompte pour continuer.");
            setShowBackdrop(false);
            return;
        }

        if (!depositResult?.docId || depositResult?.docId?.length<3){
            handleOpenSnackbar("Quelque chose s'est mal passé, veuillez recommencer.");
            setShowBackdrop(false);
            return;
        }
        setIsDepositDialogOpen(false);
        setShowBackdrop(true);
        try {
            await depTempToRdv({dataToSpread:{
                    depositRef : depRef, docId : depositResult.docId
                }});
        } catch (e) {
            console.log(e.message);
            handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.");
            cleanLocalStorage();
            setShowBackdrop(false);
            navigate("/"+id);
            return;
        }

        if (dbObj?.isGoogle){
            try {
                //needs to be base32 encoded to be added as calendarId - this allow binding to "rdv" collection

                const encodedId = Array.from(new TextEncoder().encode(String(depositResult.docId)))
                    .map(byte => (byte < 10 ? '0' : '') + byte.toString(32))
                    .join('')
                    .toLowerCase();

                // That is a way to decode, case preserved, for later use
                /*
                const decodedId = new TextDecoder().decode(
                     new Uint8Array(encodedId.match(/.{1,2}/g).map(chunk => parseInt(chunk, 32)))
                 );
                 console.log("decoded => ", decodedId);*/

                await createCalendarEvent({
                    summary: dbObj.prestaSnapshot.title,
                    startDateTime: new Date(dbObj.start).toISOString(),
                    endDateTime: new Date(dbObj.endDateClient).toISOString(),
                    docId : encodedId.toLowerCase(),
                    uid : dbObj.uid,
                    description: "Client : " + dbObj.custName + " - Email : " + dbObj.custAddress + " - Téléphone : " + dbObj.custPhone + " - Gérer : https://my.faismoibg.com/rdvdetails/" + depositResult.docId,
                })
            } catch (e) {
                console.log(e.message);
                console.error(e);
            }
        }

        navigate("/"+id+"/rdvstatus/"+depositResult.docId);
    };

    const openDepositDialog =  () => {
        setIsDepositDialogOpen(true);
    }

    const updateDepRef =  (event) => {
        setDepRef(event.target.value);
    }

    const navigateBack = async () => {
        if (depositResult){
            setShowBackdrop(true);
            handleOpenSnackbar("Annulation de votre réservation en cours");
            try {
                await remTempRes({
                    tempId:depositResult.docId
                })
            } catch (e) {
                console.log(e);
            }
            cleanLocalStorage();
            setShowBackdrop(false);
            navigate('/'+id);
            return;
        }
        navigate(-1);
    }


    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const handleConfirm = async (withQuestionnaire) => {
        setShowBackdrop(true);
        if (depositResult){
            const now = new Date().getTime();
            const endTime = new Date(depositResult.date).getTime() + 30 * 60 * 1000;
            const distance = endTime - now;
            if (distance <= 0) {
                handleOpenSnackbar("Le délai pour réserver ce créneau a expiré, veuillez recommencer le processus de réservation.");
                setShowBackdrop(false);
                return;
            }
            setIsDepositDialogOpen(true);
            // either return or open dialog if time is still valid to confirm RDV

            //handleOpenSnackbar("Vous devez entrer le nom de votre client");

            setShowBackdrop(false);
            return;
        }
        cleanLocalStorage();
        if (formData.nom.length<2){
            handleOpenSnackbar("Vous devez entrer votre nom");
            setShowBackdrop(false);
            return;
        } else if (!validateEmail(formData.email)){
            handleOpenSnackbar("Votre adresse email ne semble pas valide, vérifiez la.");
            setShowBackdrop(false);
            return;
        }
        if (formData.phone.length>0){
            const phoneObj = isValidFrenchPhoneNumber(formData.phone);
            if (!phoneObj.isValid){
                setShowBackdrop(false);
                handleOpenSnackbar("Le numéro de téléphone n'est pas français ou pas valide, vérifiez le.");
                return;
            }
        }
        const startDateStr = selectedDate + " " + selectedTime;
        const [date,[day,month,year]] = parseFrenchDateWithTimezone(startDateStr, userObject?.timezone);
        let endDateClient;
        let endDate;
        try {
            endDateClient = addDurationToTime(prestaObject.prestaDuration,date);
            endDate = addDurationToTime(prestaObject.breakDuration,endDateClient);
        } catch (e){
            handleOpenSnackbar("Quelque chose s'est mal passé, recommencez ou contactez nous.")
            console.log(e.message);
            return;
        }
        const uidv4 = v4();
        let isValidated = true;
        if (prestaObject?.isValidation){
            isValidated = false;
        }
        let dbObject = {
            dateStr : startDateStr,
            timeStr : selectedTime,
            prestaId : prestaObject.documentId,
            uid : prestaObject.uid,
            start : date,
            end : endDate,
            endDateClient: endDateClient,
            isCanceled : false,
            day : day,
            month : month,
            year : year,
            timestamp : serverTimestamp(),
            prestaSnapshot : prestaObject,
            custName : formData.nom,
            custAddress : formData.email,
            custPhone: formData.phone,
            cancelId: uidv4,
            isValidated : isValidated,
            isGoogle : agendaObject?.googleAGCode,
        }

        if (!withQuestionnaire){
            let docId = ""
            try {
                const currentDate = new Date(dbObject.year, dbObject.month, dbObject.day);
                const offsetInMinutes = currentDate.getTimezoneOffset();
                const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
                const adjustedDate = new Date(currentDate.getTime() + Math.abs(offsetInMilliseconds));
                let result;
                if (prestaObject?.isDeposit){
                    result = await updateDepositRdv({dataToUpdate:dbObject, inputDate:adjustedDate});
                } else {
                    result = await updateRdv({dataToUpdate:dbObject, inputDate:adjustedDate});
                }
                if (result?.data?.success){
                    docId = result.data.docId;
                    ReactGA.event({
                        category: "rdvclient",
                        action: "validate",
                        value: 1,
                        nonInteraction: true,
                        transport: "xhr",
                    });
                } else if (result?.data?.docId==="blocked"){
                    handleOpenSnackbar("Ce créneau a été réservé à l'instant, essayez de choisir un autre créneau.");
                    setShowBackdrop(false);
                    return;
                } else {
                    handleOpenSnackbar("Quelque chose s'est mal passé, contactez nous.");
                    setShowBackdrop(false);
                    return;
                }

                if (prestaObject?.isDeposit){
                    setDepositResult(result.data);
                    saveToLocalStorage("depositResult", result.data);
                    saveToLocalStorage("formData", formData);
                    saveToLocalStorage("prestaId", id2);
                    saveToLocalStorage("dbObject", dbObject);
                    saveToLocalStorage("agendaObject", agendaObject);
                    saveToLocalStorage("userObject", userObject);
                    saveToLocalStorage("selectedTime", selectedTime);
                    saveToLocalStorage("selectedDate", selectedDate);
                    setDbObj(dbObject);
                    setLockFields(true);
                    setShowBackdrop(false);
                    openDepositDialog();
                    return;
                }

            } catch (e){
                console.log("Error => ", e.message);
                handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.");
                setShowBackdrop(false);
                return;
            }

            if (agendaObject?.googleAGCode){
                try {
                    //needs to be base32 encoded to be added as calendarId - this allow binding to "rdv" collection

                    const encodedId = Array.from(new TextEncoder().encode(String(docId)))
                        .map(byte => (byte < 10 ? '0' : '') + byte.toString(32))
                        .join('')
                        .toLowerCase();

                    // That is a way to decode, case preserved, for later use
                    /*
                    const decodedId = new TextDecoder().decode(
                         new Uint8Array(encodedId.match(/.{1,2}/g).map(chunk => parseInt(chunk, 32)))
                     );
                     console.log("decoded => ", decodedId);*/

                    await createCalendarEvent({
                        summary: prestaObject.title,
                        startDateTime: new Date(dbObject.start).toISOString(),
                        endDateTime: new Date(dbObject.endDateClient).toISOString(),
                        docId : encodedId.toLowerCase(),
                        uid : agendaObject.uid,
                        description: "Client : " + formData.nom + " - Email : " + formData.email + " - Téléphone : " + formData.phone + " - Gérer : https://my.faismoibg.com/rdvdetails/" + docId,
                    })
                } catch (e) {
                    console.log(e.message);
                }
            }
            setShowBackdrop(false);
            navigate("/"+id+"/rdvstatus/"+docId);
        } else {
            setShowBackdrop(false);
            navigate("/"+id+"/rdvquestions/"+id2, {
                state: {
                    dbObject : dbObject
                }
            });
        }
        //await navigateToSuccess(docId, date, endDate, uidv4);
    }

    return (
        <div className="sub-container">

            {displayPage===null ? (
                <div className="sub-container-no-top">
                    <div style={{display:"flex", width:"100%", flexDirection:"column",
                        justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                        <Skeleton style={{marginTop:"10px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    </div>
                </div>
            ) : (
                displayPage ? (
                    <div className="sub-container-no-top">
                        <span className="title-style" style={{textAlign:"center"}}>Renseignez vos coordonnées de contact</span>
                        <div className="field-container">
                            <div className="title-field-container">
                                  <span className="text-field-style">
                                      Nom <span className="aster">*</span>
                                  </span>
                            </div>
                            <TextField disabled={lockFields} onChange={handleFormChange} value={formData?.nom} className="field-style"
                                       label="Ecrire" variant="outlined" name="nom" />
                        </div>


                        <div className="field-container">
                            <div className="title-field-container">
                                  <span className="text-field-style">
                                      Email <span className="aster">*</span>
                                  </span>
                            </div>
                            <TextField disabled={lockFields} onChange={handleFormChange} value={formData?.email} className="field-style"
                                       label="Ecrire" variant="outlined" name="email" />
                        </div>

                        <div className="field-container">
                            <div className="title-field-container">
                                  <span className="text-field-style">
                                      Téléphone
                                  </span>
                            </div>
                            <TextField disabled={lockFields} onChange={handleFormChange} value={formData?.phone} className="field-style"
                                       label="Ecrire" variant="outlined" name="phone" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                background: "linear-gradient(180deg, #4808FF 0%, #9747FF 100%)",
                                marginTop: "50px",
                                minHeight: "10px",
                                borderRadius: "15px",
                                boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                width: "100%",
                                flexDirection: "column", // Change to column to stack items vertically
                            }}
                        >
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row", // Change to row to have icon and content side by side
                                    alignItems: "center", // Center icon and content vertically
                                    padding: "1px 20px", // Add padding to create some space around the content
                                    marginBottom:"25px"
                                }}
                            >
                                <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                    <img src={calPlusBlue} style={{width:"30px", height:"30px"}}/>
                                </div>
                                <div
                                    style={{
                                        width: "90%", // Adjusted width to make space for the close icon
                                        marginLeft: "10px",
                                    }}
                                >
                                    <span style={{color:"white"}}>{selectedDate} à {selectedTime}</span>
                                </div>
                            </div>
                            {(prestaObject?.selectedAddress?.length>1 && prestaObject?.isAddressVisible) ?
                                (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row", // Change to row to have icon and content side by side
                                            alignItems: "center", // Center icon and content vertically
                                            padding: "1px 20px", // Add padding to create some space around the content
                                            marginBottom:"25px"
                                        }}
                                    >
                                        <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                            <img src={pinMapBlue} style={{width:"30px", height:"30px"}}/>
                                        </div>
                                        <div
                                            style={{
                                                width: "90%", // Adjusted width to make space for the close icon
                                                marginLeft: "10px",
                                            }}
                                        >
                                            <span style={{color:"white"}}>{prestaObject.selectedAddress}</span>
                                        </div>
                                    </div>
                                ) : <div></div>
                                /*<div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row", // Change to row to have icon and content side by side
                                        alignItems: "center", // Center icon and content vertically
                                        padding: "1px 20px", // Add padding to create some space around the content
                                        marginBottom:"25px"
                                    }}
                                >
                                    <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                        <img src={pinMapBlue} style={{width:"30px", height:"30px"}}/>
                                    </div>
                                    <div
                                        style={{
                                            width: "90%", // Adjusted width to make space for the close icon
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <span style={{color:"white"}}>Vous n'avez pas séléctionné d'adresse pour cette prestation</span>
                                    </div>
                                </div>*/

                            }


                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row", // Change to row to have icon and content side by side
                                    alignItems: "center", // Center icon and content vertically
                                    padding: "1px 20px", // Add padding to create some space around the content
                                    marginBottom:"25px"
                                }}
                            >
                                <div style={{ width: "10%", alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                    <img onLoad={handleImageLoad}
                                         className="fade-in" style={{color:"white", borderRadius:"50%", width:"30px",height:"30px", objectFit:"cover"}}
                                         src={userObject.imageUrl.length> 0 ? userObject.imageUrl : userlogo }/>
                                </div>
                                <div
                                    style={{
                                        width: "90%", // Adjusted width to make space for the close icon
                                        marginLeft: "10px",
                                    }}
                                >
                                    <span style={{color:"white"}}>{userObject.pseudo}</span>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row", // Change to row to have icon and content side by side
                                    alignItems: "center", // Center icon and content vertically
                                    padding: "1px 20px", // Add padding to create some space around the content
                                    marginBottom:"25px"
                                }}
                            >
                                <div style={{ width: "10%", alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                    <img onLoad={handleImageLoad}
                                         className="fade-in" style={{color:"white", borderRadius:"5px", width:"30px",height:"30px", objectFit:"cover"}}
                                         src={prestaObject.imageLinks.length> 0 ? prestaObject.imageLinks[0].imageLink : "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.." }/>
                                </div>
                                <div
                                    style={{
                                        width: "90%", // Adjusted width to make space for the close icon
                                        marginLeft: "10px",
                                    }}
                                >
                                    <span style={{color:"white", fontWeight:700}}>{prestaObject.title}</span>
                                </div>
                            </div>

                        </div>

                        <div className="button-container" style={{marginTop:"80px"}}>
                            <Button onClick={() => handleConfirm(prestaObject?.questions?.length>0)} className="button-style button-style-noborders" variant="contained">
                                <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                    {prestaObject?.questions?.length>0 ? ("Continuer") :
                                        (prestaObject?.isDeposit ? "Régler acompte pour réserver" : "Confirmer votre réservation")
                                    }
                                </Typography>
                            </Button>
                        </div>

                        <div className="button-container" style={{marginTop:"15px"}}>
                            <Button style={{marginBottom:!prestaObject?.questions?.length>0 ? "50px" : "100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                                <Typography variant="button" style={{ textTransform: 'none' }}>
                                    Retour
                                </Typography>
                            </Button>
                        </div>
                        {!prestaObject.questions.length>0 && (
                            <span style={{width:"100%", marginBottom:"50px"}}>
                            En continuant vous confirmez avoir pris connaissance de <a rel="noreferrer"  href="https://shine.faismoibg.com/politique-confidentialite" target="_blank">notre politique de confidentialité</a> ainsi que de <a rel="noreferrer"  href="https://shine.faismoibg.com/cgu" target="_blank">nos CGU</a>
                        </span>)}

                    </div>
                ) : (
                    <div className="sub-container-no-top">
                        <div style={{display: "flex", width:"80%", padding: "20px", flexDirection: "column", justifyContent: "center", marginTop:"30px",
                            alignItems: "center", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                        <span style={{lineHeight: "24px"}}>
                                            {isAlreadyValidated ? "Vous avez déjà validé ce rendez-vous 🤷" : "Cette page n'existe pas  🤷"}
                                        </span>
                        </div>
                    </div>
                )
            )}

            <Dialog
                open={isDepositDialogOpen}
                onClose={handleDepositDialogCloseOutside}
                fullWidth={!isLargeScreen}
                PaperProps={{
                    style: {
                        minWidth: isLargeScreen ? '500px' : 'auto',
                        maxWidth: isLargeScreen ? '500px' : 'auto',
                    },
                }}
            >
                <DialogContent style={{ backgroundColor: "white" }}>
                    <div style={{display:"flex", flexDirection:"column", padding:"10px 6px"}}>
                        <CountdownTimer dbTimestamp={depositResult?.date}></CountdownTimer>
                        <span style={{fontSize:"16px", lineHeight: "20px", marginTop:"20px"}}>
                            ⚠️ Nous vous avons également envoyé un mail récapitulatif avec un lien vous permettant de revenir sur cette page afin de valider l’acompte.
                        </span>
                        <span style={{fontSize:"16px", lineHeight: "20px", marginTop:"20px"}}>
                            Afin de valider cette réservation, nous vous invitons à régler cet acompte, en suivant les étapes ci-dessous :
                        </span>
                        <span style={{fontSize:"16px", marginTop:"30px", color:"#4808FF", fontWeight:700}}>
                            Etape 1. <span style={{fontSize:"16px", marginTop:"30px", color:"black", fontWeight:700}}>Accédez à la page de paiement via l’îcone ci-dessous.</span>
                        </span>
                        <div style={{ width: "100%", marginTop: "20px", display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <div style={{ display: "flex", justifyContent: "flex-start", flexWrap:"wrap" }}>
                                {prestaObject?.deposit?.paymentLinks.map((socialLink, index) => {
                                    if (socialLink?.link?.length > 0) {
                                        const iconComponent = {
                                            paypal: <FaPaypal style={{ fontSize: '0.9rem', background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white", }} />,
                                            lydia: <img src={icLydia} style={{ background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white", width:"14px", height:"14px" }} />,
                                            paylib: <img src={icPaylib} style={{ background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white",width:"14px", height:"14px", objectFit:"cover",scale:"1.2" }} />,
                                            stripe: <FaStripe style={{ background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", fontSize: '0.9rem', color: "white", scale:"1.3"  }} />,
                                            sumup: <img src={icSumUp}  style={{ background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white", width:"14px", height:"14px", objectFit:"cover"}} />,
                                            wise: <img src={icWise} style={{ background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white", width:"14px", height:"14px", objectFit:"cover", scale:"1.8" }} />,
                                            revolut: <img src={icRevolut} style={{ background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white", width:"14px", height:"14px", objectFit:"cover", scale:"1.2" }} />,
                                            site: <FaGlobe style={{ fontSize: '0.9rem', background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white"}} />,
                                        }[socialLink?.network];

                                        return (
                                            <a style={{textDecoration:"none"}} href={socialLink.link} target="_blank" key={socialLink?.network+index}>
                                                <Button startIcon={iconComponent} className="button-style-borders" key={index} style={{ padding: "10px 25px", marginTop:"10px" }}>
                                                    <Typography style={{textTransform: 'none', fontSize:"16px", fontWeight:400}}>
                                                        {socialLink.network}
                                                    </Typography>
                                                </Button>
                                            </a>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                        <span style={{fontSize:"16px", marginTop:"30px", color:"#4808FF", fontWeight:700}}>
                            Etape 2. <span style={{fontSize:"16px", marginTop:"30px", color:"black", fontWeight:700}}>Procédez au règlement de l’acompte directement sur la page de paiement.</span>
                        </span>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"start", marginTop:"10px", alignItems:"center"}}>
                            <span>
                                Montant de l’acompte :
                            </span>
                            <div style={{display: "flex", padding:" 5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", fontWeight:700, fontSize:"14px", marginLeft:"12px"}}>
                                {prestaObject?.deposit?.amount}
                            </div>
                        </div>

                        <span style={{fontSize:"16px", marginTop:"30px", color:"#4808FF", fontWeight:700}}>
                            Etape 3. <span style={{fontSize:"16px", marginTop:"30px", color:"black", fontWeight:700}}>Indiquez le numéro de transaction de votre paiement, puis valider l’acompte.</span>
                        </span>

                        <div style={{width:"100%", display:"flex", flexDirection:"row", marginTop:"14px" }}>
                            <TextField className="field-style" label="Numéro de transaction"  variant="outlined"
                                       onChange={(event) => updateDepRef(event)} />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "white" }}>
                    <Button className="blue-button button-style-noborders" style={{marginLeft:"32px", marginRight:"32px", marginBottom:"30px", marginTop:"10px", backgroundColor:"black"}} variant="button"
                            onClick={() => {
                                handleDepositValidated(); // Replace indexToDelete with the actual index you want to delete
                            }}
                    >
                        <Typography variant="button" style={{textTransform: 'none', color:"white"}}>
                            Confirmer
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
        </div>
    )
}